import React from "react";
import Banner from "../Banner/Banner";
import logo from "../../assets/Figma/FavCon 1.png";
import Footer from "./Footer";
import ReactGA from "react-ga4";

const Home = () => {
  // GA Tracker
  ReactGA.event({
    category: "page_view",
    action: "viewed home page",
    label: "Homepage visit",
  });

  return (
    <div class="intro-page">
      <Banner />
      <Footer logo={logo} />
    </div>
  );
};

export default Home;
