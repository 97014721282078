import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";

import Button from "@mui/material/Button";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import imgPath1 from "../../assets/Figma/1stHome.png";
import imgPath2 from "../../assets/Figma/HappyOwners1.png";
import imgPath3 from "../../assets/Figma/WeClosed.png";
import logo from "../../assets/Figma/bannerLogo.jpg";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const queryParams = new URLSearchParams(window.location.search);
const caller_id = queryParams.get("ref");

function Banner() {
  let navigate = useNavigate();
  let GoQuestion = () => {
    // GA Tracker
    ReactGA.event({
      category: "button_click",
      action: "clicked Get Started button",
      label: "Get Started Button",
    });

    if (caller_id !== "" || caller_id !== null) {
      navigate(`/Question?ref=${caller_id}`);
    } else {
      navigate(`/Question`);
    }
  };
  const images = [
    {
      label: "What's your Ownerscope?",
      Details:
        "This 3-minute personality quiz will bring you and your circle closer to owning your next property.",
      imgPath: imgPath1,
      logo: logo,
      button: (
        <Button
          variant="contained"
          size="large"
          className="rectangle"
          style={{ color: "#644200" }}
          onClick={GoQuestion}
        >
          get started
        </Button>
      ),
    },
    {
      label: "What's your Ownerscope?",
      Details:
        "Owning or renting are influenced by your social circles and your personality type -- we call this your Ownerscope.",
      imgPath: imgPath2,
      logo: logo,
      button: (
        <Button
          variant="contained"
          size="large"
          className="rectangle"
          style={{ color: "#644200" }}
          onClick={GoQuestion}
        >
          get started
        </Button>
      ),
    },
    {
      label: "What's your Ownerscope?",
      Details: "Everyone has an Ownerscope. What's yours?",
      imgPath: imgPath3,
      logo: logo,
      button: (
        <Button
          variant="contained"
          size="large"
          className="rectangle"
          style={{ color: "#644200" }}
          onClick={GoQuestion}
        >
          get started
        </Button>
      ),
    },
  ];
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  //newly added
  const [readMoreShow, setReadMoreShow] = React.useState(false);

  const readMore = () => {
    return (
      <>
        <p>
          Ownerscope makes it easy to have these valuable conversations. Just
          complete the personality quiz, then ask the people in your circle,
          “What’s your Ownerscope?”
        </p>
        <div
          style={{
            background: "#fff2cc",
          }}
        >
          <p>
            Ownerscope lets you know your personality type as a home buyer, and
            it tells you about the other personality types who will motivate you
            on your path to ownership.
          </p>
          <p>
            For first-time home buyers, Ownerscope is the first-step toward
            closing your first home purchase, and it’s free.
          </p>
          <p>
            Achieving first-time home ownership requires insight. Ownerscope
            gives you insight, and insight gives you an advantage.
          </p>
        </div>
        <p>
          After you complete the quiz, Ownerscope connects you with useful
          content and conversation-starter topics to help you advance toward
          your goal of home ownership
        </p>
        <p>
          Owning a comfortable, affordable home is one of the most liberating
          achievements that you can reach. One of the best pathways to achieving
          this goal is simply by communicating with friends & family about it.
          So start talking!
        </p>
        <p>
          If you’ve got questions, ask them. If you've got insights, share. If
          you’ve got doubts, be real. If you can make someone laugh, do it.
          Achieving home ownership is not just about mortgages and down
          payments. It’s also about relationships, culture, humor and more. It’s
          about your personality. It’s about your circle. It’s about you!
        </p>
        <p>
          The purpose of Ownerscope is to help people envision households where
          they can live, love and thrive.
        </p>
        <div style={{ textAlign: "center" }}>
          <p
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "bolder",
              fontSize: "15px",
              lineHeight: "28px",
              color: "#000000",
              paddingTop: "10px",
              minHeight: "60px",
              textAlign: "center",
            }}
          >
            Everyone has an Ownerscope. <br />
            What's yours?
          </p>
        </div>
        <p className="mt-4 mb-1 slider_button"> {images[activeStep].button}</p>
      </>
    );
  };

  return (
    <div className="slider-wrapper">
      <div className="slider-banner">
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          interval="5000"
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <div key={index} className="slide-box">
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  component="img"
                  sx={{
                    display: "block",

                    overflow: "hidden",
                    width: "100%",
                  }}
                  src={step.imgPath}
                  alt={step.label}
                />
              ) : null}

              <img
                src={images[activeStep].logo}
                alt="logo"
                className="slider_logo"
              />
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </div>

      <div className="slider-content">
        <h1 className="slider_lebel">{images[activeStep].label}</h1>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              className={
                theme.direction === "rtl" ? "ArrowLeftIcon" : "ArrowRightIcon"
              }
            >
              {theme.direction === "rtl" ? (
                <ArrowLeftIcon />
              ) : (
                <ArrowRightIcon />
              )}
            </Button>
          }
          backButton={
            activeStep === 2 ? null : (
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
                className={
                  theme.direction === "rtl" ? "ArrowRightIcon" : "ArrowLeftIcon"
                }
              >
                {theme.direction === "rtl" ? (
                  <ArrowRightIcon />
                ) : (
                  <ArrowLeftIcon />
                )}
              </Button>
            )
          }
        />
        <p className="slider_details">{images[activeStep].Details}</p>
        <p className="mt-4 mb-1 slider_button"> {images[activeStep].button}</p>
      </div>
      <div
        className="mobile-content"
        style={{ backgroundColor: "white", padding: "13px" }}
      >
        <p>
          <b>Ownerscope</b> is an ice-breaker to help friends and relatives talk
          comfortably about the goal of owning property.
        </p>
        <p>
          Home ownership tends to happen within social circles. People who
          actively talk with their circle about owning property have an
          advantage for achieving their goal.
        </p>
        <p style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={() => setReadMoreShow(true)}>
            <b>[ Read More]</b>
          </Button>
        </p>
        {readMoreShow && readMore()}
      </div>
    </div>
  );
}

export default Banner;
