import React from 'react';
import { useState, useEffect } from 'react';
import { isIOS, isMacOs } from 'react-device-detect';
import email from '../../assets/Images/social/email.svg';
import facebook from '../../assets/Images/social/facebook.svg';
import linkedin from '../../assets/Images/social/linkedin.svg';
import messenger from '../../assets/Images/social/messenger.svg';
import sms from '../../assets/Images/social/sms.svg';
import whatsapp from '../../assets/Images/social/whatsapp.svg';   
import api from "../../Api";

const Key = () => {

   const hostname = "https://www.ownerscope.com"
   
   const queryParams = new URLSearchParams(window.location.search);
   const caller_id = queryParams.get('ref');
   const title = queryParams.get('title');
   const description = queryParams.get('desc');
   const invite_url = hostname + "?ref=" + caller_id;

   const ToForward = queryParams.get('content_id');
   const ConvoQuestion = queryParams.get('ques');

   let userIsFromEmail = false;
   if (ToForward !== null && ConvoQuestion !== null) {
      userIsFromEmail = true;
   }

   let missingParams = false;
   if (caller_id === null && title === null && description === null) {
      missingParams = true;
   }

   const [width, setWidth] = useState(window.innerWidth);

   function handleWindowSizeChange() {
      setWidth(window.innerWidth);
   }

   useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
         window.removeEventListener('resize', handleWindowSizeChange);
      }
   }, []);

   let emailVal = React.createRef();
   let recipientEmail = React.createRef();
   const [userExists, setUserExists] = useState(false);
   const [userEmail, setUserEmail] = useState("");
   function checkUserExists() {
      let userExistsAPI = "/user";
      let body = {
         "email" : emailVal.current.value,
         "caller_id": caller_id
      };
      setUserEmail(emailVal.current.value);

      const res = api.post(userExistsAPI, body);
      res.then(response => {
         if (response.status === 200) {
            console.log(response.data.status);
            if (response.data.status === true) {
               setUserExists(true);
            } else {
               alert("Looks like you haven't done the Ownerscope quiz yet. It only takes 3 minutes. Please Check Your Ownerscope, then you can share with your circle.");
            }
         } else {
            console.log("Something Went Wrong");
         }
      })
   }


   const forwardEmail = () => {
      let forwardEmailAPI = "/convo-starter_forward";
      let body = {
         "email" : userEmail,
         "recipient_email" : recipientEmail.current.value,
         "content_id": ToForward,
         "caller_id" : caller_id,
         "user_key" : hostname + "/key?ref=" + caller_id,
      };

      const res = api.post(forwardEmailAPI, body);
      res.then(response => {
         if (response.status === 200) {
            console.log(response.data.status);
            if (response.data.status === true) {
               alert(response.data.message);
            } else {
               alert(response.data.message);
            }
         } else {
            console.log("API request failed");
         }
      })
   };

   const copyURL = () => {
      var copyText = document.getElementById("inviteKey");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value)
      .then(() => {
         alert("Link copied to clipboard! Now share it with others to reach Full Circle status.");
      })
      .catch(() => {
         alert("Sorry, could not copy link to clipboard. Please copy it manually.");
      });
   };

   const isMobile = width <= 768;

   let invite_url_messenger = "";
   let invite_url_whatsapp = "";
   let invite_url_sms = "";
   let invite_url_linkedin = "https://www.linkedin.com/sharing/share-offsite/?url=" + hostname + "/?ref%3D" + caller_id + "&title=" + title;
   let invite_url_facebook = "https://www.facebook.com/sharer/sharer.php?u=" + invite_url + "&quote=" + title + ". " + description;
   let invite_url_gmail = "https://mail.google.com/mail/u/0/?to&su=Hey!+Check+out+Ownerscope.&body=" + title + ". " + description + "%0D%0A%0D%0A" + invite_url + "&bcc&cc&fs=1&tf=cm";
   
   if (isMobile) {
      invite_url_messenger = "fb-messenger://share/?app_id=291494419107518&link=" + invite_url + "&redirect_uri=" + hostname;
      invite_url_whatsapp = "whatsapp://send?text=" + title + ". " + description + "%0D%0A" + invite_url;
   } else {
      invite_url_messenger = "https://www.facebook.com/dialog/send?app_id=291494419107518&link=" + invite_url + "&redirect_uri=" + hostname;
      invite_url_whatsapp = "https://web.whatsapp.com/send?text=" + title + ". " + description + "%0D%0A" + invite_url;
   }

   if (isIOS || isMacOs) {
      invite_url_sms = "sms:&body=" + title + ".  " + description + "%0D%0A" + invite_url;
   } else {
      invite_url_sms = "sms://?body=" + title + ".  " + description + "%0D%0A" + invite_url;
   }

   let currentYear = new Date().getFullYear();

   return (
      <div className='hello' id='key'>
      {!missingParams ? (
         <>
         <div className='key-container'>
            <img src='https://cdn.ownerscope.com/logo/small.jpg' alt='Ownerscope Logo' style={{maxWidth:170, maxHeight:170}}/>

         {!userIsFromEmail ? (
            <>
            <h3>Your Connection Key</h3>
            <p>People who actively talk with their circle about owning property have an advantage for achieving their goal.</p>
            <div className='copyUrl'>
               <input type="text" id="inviteKey" value={invite_url} readOnly/>
               <button onClick={copyURL}>Copy</button>
            </div>
            <div id="social-holder">
               <a class='social' id='facebook' href={invite_url_facebook} target="popup">
                  <img src={facebook} rel='noopener' alt="Share on Facebook" />
               </a>
               <a class='social' id='messenger' target="popup" href={invite_url_messenger}>
                  <img src={messenger} rel='noopener' alt="Share via Messenger" />
               </a>
               <a class='social' id='whatsapp' target="popup" href={invite_url_whatsapp}>
                  <img src={whatsapp}rel='noopener' alt="Share via Whatsapp" />
               </a>
               <a class='social' id='linkedin' target="popup" href={invite_url_linkedin}>
                  <img src={linkedin} rel='noopener' alt="Share on Linkedin" />
               </a>
               <a class='social' id='email' target="popup" href={invite_url_gmail}>
                  <img src={email} rel='noopener' alt="Share via Gmail" />
               </a>
               <a class='social' id='sms' href={invite_url_sms} target="popup">
                  <img src={sms} rel='noopener' alt="Share via SMS" />
               </a>
            </div>

            <div className='key-container'>
               <p>Invite your circle by sending the invitation link or posting it on your social feeds.</p>
               <br/>
               <hr/>
               <small className='footer-links'><a href='/privacy-policy'>Privacy Policy</a> | <a href='/term-settings'>Terms of Use</a></small><br/>
               <small>All Rights Reserved © {currentYear} Ownerscope</small>
            </div>
            </>
         ):(
         <>
            {userExists ? (
               <>
               <h3>Smart Move</h3>
               <p>People who actively talk with their circle about owning property have an advantage for achieving their goal.</p>
               
               <input ref={recipientEmail} type="text" className="generic-input" placeholder="Recipient's Email Address" /><br/>
               <button className='btn-key yellow' onClick={forwardEmail}>Forward Email to your friend</button><br/><br/>

               <small>Or copy and share the question with your friends.</small>
               <div className='copyUrl'>
                  <input type="text" id="inviteKey" value={ConvoQuestion} readOnly/>
                  <button onClick={copyURL}>Copy</button>
               </div>

               <hr/>

               <h5>Continue to use your key to reach full-circle.</h5><br/>
               <div className='copyUrl'>
                  <input type="text" id="inviteKey" value={invite_url} readOnly/>
                  <button onClick={copyURL}>Copy</button>
               </div>

               <div id="social-holder">
                  <a class='social' id='facebook' href={invite_url_facebook} target="popup">
                     <img src={facebook} rel='noopener' alt="Share on Facebook" />
                  </a>
                  <a class='social' id='messenger' target="popup" href={invite_url_messenger}>
                     <img src={messenger} rel='noopener' alt="Share via Messenger" />
                  </a>
                  <a class='social' id='whatsapp' target="popup" href={invite_url_whatsapp}>
                     <img src={whatsapp}rel='noopener' alt="Share via Whatsapp" />
                  </a>
                  <a class='social' id='linkedin' target="popup" href={invite_url_linkedin}>
                     <img src={linkedin} rel='noopener' alt="Share on Linkedin" />
                  </a>
                  <a class='social' id='email' target="popup" href={invite_url_gmail}>
                     <img src={email} rel='noopener' alt="Share via Gmail" />
                  </a>
                  <a class='social' id='sms' href={invite_url_sms} target="popup">
                     <img src={sms} rel='noopener' alt="Share via SMS" />
                  </a>
               </div>

               <div className='key-container'>
                  <p>Invite your circle by sending the invitation link or posting it on your social feeds.</p><br/><hr/>
                  <small className='footer-links'><a href='/privacy-policy'>Privacy Policy</a> | <a href='/term-settings'>Terms of Use</a></small><br/>
                  <small>All Rights Reserved © {currentYear} Ownerscope</small>
               </div>
               </>

            ) : (
               <>
               <div className='key'>
                  <h5>Please Verify Your Email Address</h5>
                  <p>The same one you used for Ownerscope personality quiz.</p><br/>
                  <div>
                     <input type="text" ref={emailVal} className="generic-input" placeholder='Email Address'/>
                     <button class="btn-key yellow" onClick={checkUserExists}>Continue</button>
                  </div>
                  <br/><hr/>
                  <h5>If you haven't already done the Ownerscope quiz</h5><br/>
                  <a class="btn-key blue" href={invite_url} target='_blank' rel='noopener noreferrer'>Check Your Ownerscope</a>
               </div>
               <div className='key-container'>
                  <br/><hr/>
                  <small className='footer-links'><a href='/privacy-policy'>Privacy Policy</a> | <a href='/term-settings'>Terms of Use</a></small><br/>
                  <small>All Rights Reserved © {currentYear} Ownerscope</small>
               </div>
               </>
            )}
         </>
         )}
         </div>
         </>

         ) : (
            <div className='key-container'>
               <h3>Invalid Key</h3>
            </div>
         )}
      </div>
   );
};

export default Key;