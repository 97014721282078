import React from "react";


const PrivacyPolicy = (props) => {
 
  return (
      <div className="privacypolicymain">
        <div className="title">
          <h1>Privacy Policy</h1>
          <span>Last Modified May 23, 2022</span>
        </div>
        <div className="content">
          <p>Ownerscope Worldwide LLC d/b/a Ownerscope is committed to protecting your privacy.  The paragraphs 
              below explain our policies regarding how Ownerscope handles your information. If you do not agree with 
              these policies, then you have the choice not to use our Website. If you have any questions about this 
              policy or our use of your personal information please contact us by sending an email to 
              connect@ownerscope.com. </p>
              <h4><b>1. Collection and Use of Personal Information</b></h4>
              <span>Ownerscope may collect the following types of information: </span>
              <ul>
                <li><strong>User-Provided Information.</strong> In order to view and receive the results of your personality quiz, the 
                    Website requires you to submit personal information to us (specifically your email address) 
                    through the internet browser-based form. We retain and use your email address (1) to send your 
                    personality results to you in an email message, (2) to generate an invitation link which you can 
                    share with other people whom you want to invite to do the Ownerscope quiz, and (3) to determine
                    if any of the people whom you invite have personality results that sync with yours based on your 
                    Full Circle suggestions.</li>
                    <li><strong>IP Addresses.</strong> Our web server automatically recognizes each unique visitor’s domain name or IP
                    address (where possible). Ownerscope collects IP addresses and related information for the 
                    purposes of system administration, to assess the traffic to our Website and to maintain and 
                    improve our Website. It is not our practice to link IP addresses to your personally identifiable 
                    information; however, we reserve the right to use IP addresses to identify a user if, in our sole 
                    determination, it becomes necessary to protect the compelling interest of our Website, our users, 
                    or to comply with laws, court orders, or law enforcement requests.</li>         
              </ul>
              <h4><b>2. Sharing Your Information; Limitations</b></h4>
                  <p>We will not share your personal information with third parties outside of our Affiliates unless we have your 
                    consent or have a good faith belief that such disclosure is required by law or court order or otherwise 
                    necessary to prevent an undue or irreparable harm to someone.</p>
                  <p>Ownerscope may share personally identifiable information with its Affiliates and business partners 
                    necessary to complete online transactions as part of its standard business operations and to assist us in 
                    offering products or services to you if you have opted in for Insights or other resources or opportunities 
                    through Ownerscope.</p>
              <h4><b>3. Protecting Your Personal Information</b></h4>
                  <p>Ownerscope takes reasonable measures to protect our users’ personal information.  To complete the 
                    personality quiz and obtain the results, you need only provide your email address and no other personal 
                    identifying information.</p>
                  <p>Once entered into our system, access to users’ personal information is restricted to only those persons 
                  who need access our system to perform the services we offer, and such access is granted with strict non-
                  disclosure requirements.  Please note that your personal information may be stored on computer systems
                  maintained by a service provider of Ownerscope, each of which will also be bound by an agreement to 
                  protect the confidentiality of your personal information.
                  </p>
                  <p>Additionally, Ownerscope may use Secured Sockets Layer (SSL) technology to secure the transfer of 
                    information. However, SSL technology does not guarantee that data transmission over the Internet will be
                    completely secure.  Our computer systems are located in the United States of America, but the computer systems of our service providers may be located in another country. Your submission of personal 
                    information constitutes your consent to the transfer and storage of your information across international 
                    borders.</p>
                <h4><b>4. Third-Party Links on Our Website</b></h4>
                  <p>Ownerscope may provide hyperlinks to third-party controlled websites on our Website. This privacy policy 
                    does not govern the use of such websites.  Third-party websites may have a privacy policy different from 
                    our Privacy Policy. You should review the applicable privacy policies of all other websites before providing
                    personally identifiable information in connection with such sites. We are not responsible for any actions or
                    privacy policies of such third parties.</p>
                <h4><b>5. Children’s Privacy</b></h4>
                  <p>Ownerscope does not solicit personal information from children. We abide by the United States Children’s
                    Online Privacy Protection Act and will not knowingly collect personally identifiable information from 
                    children under age 13. We recommend that parents actively participate in their children’s use of the 
                    Internet.</p>
                <h4><b>6. Requesting Review and Change to Your Personal Information</b></h4>
                  <p>If you wish to amend, correct, update, delete or opt-out of any information to which you have previously 
                    opted-in or wish to review the information we have collected about you, please contact Ownerscope via e-
                    mail at connect@ownerscope.com, and we will attend to your request and make any necessary revisions.
                    If you prefer, you can call us at <a href="tel:1-646-693-6125"><span style={{color:"#000"}}>1-646-693-6125</span></a> or send mail to us at Ownerscope, Internet Privacy 
                    Questions: 9004 161st Street, Suite 308, Queens, NY, 11432.</p>
                <h4><b>7. Changes in Terms</b></h4>
                  <p>We may change this Privacy Policy from time to time, and we will change the Effective Date located at the
                    beginning of this Privacy Policy.</p>
                <h4><b>8. Contacting Ownerscope</b></h4>
                  <p>If you have any specific questions regarding any issues about privacy on our Website, please contact 
                    Ownerscope via e-mail at connect@ownerscope.com, and we will attend to your request and make any 
                    necessary revisions.</p>
                  <p>If you prefer, you can call us at <a href="tel:1-646-693-6125"><span style={{color:"#000"}}>1-646-693-6125</span></a> or send mail to us at Ownerscope, Internet Privacy 
                    Questions: 9004 161st Street, Suite 308, Queens, NY, 11432.</p>
                <h4><b>9. Applicable Law</b></h4>
                  <p>Our collection and use of information shall be governed by laws of the State of New York.</p>
                <h4><b>10. Consent</b></h4>
                  <p>By using our Website and providing personally identifiable information about yourself, you consent to (1) 
                      our collection use and sharing of data as described in this Privacy Policy, (2) the notice, choice, access 
                      and security we are providing, and (3) the methods we have described to notify you of changes to this 
                      Privacy Policy.</p>
        </div>
      </div>
  );
};

export default PrivacyPolicy;