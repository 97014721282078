import axios from "axios";
const api = axios.create({
  baseURL: "https://api.ownerscope.com",
  // baseURL: "http://localhost:3006/ownerscope-api/src",
  imgUrl: "https://admin.ownerscope.com/adminpanel/QuestionImage/",
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
