import * as React from "react";
import Button from "@mui/material/Button";
import Vector from "../../assets/Figma/Vector.png";
import { Modal } from "react-bootstrap";
import LogoPng from "../../../src/assets/Figma/FavCon 1.png";
import { Typography } from "@mui/material";

export default function RightPopUpModal() {
  const [modalShow, setModalShow] = React.useState(false);

  function MyModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="question">
          <Button onClick={props.closeModal} class="topright">
            &times;
          </Button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="logo-img">
              <img
                className="py-2 rounded"
                src={LogoPng}
                alt="logo"
                loading="lazy"
                width="100%"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "2px solid #4565aa",
              borderRadius: "0.5rem",
              padding: "1rem 1.5rem",
              marginBottom: "1rem",
            }}
          >
            <p>
              <strong style={{ color: "#4565aa" }}>Ownerscope</strong> is an
              ice-breaker to help friends and families talk comfortably about
              the goal of owning property. People who actively talk with their
              circle about owning property are more likely to achieve their
              goal.
            </p>
            <p style={{ paddingTop: "1rem" }}>
              <strong style={{ color: "#4565aa" }}>Ownerscope</strong> makes it
              easy to have these valuable conversations. Just complete the
              personality quiz, then ask the people in your circle, “What’s your
              Ownerscope?
            </p>
          </div>
          <div
            style={{
              marginTop: "5px",
            }}
          >
            <Button
              onClick={props.closeModal}
              variant="contained"
              size="large"
              style={{
                height: "50px",
                width: "-webkit-fill-available",
                backgroundColor: "#4565aa",
                marginBottom: "1rem",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "Bold",
                }}
              >
                Close
              </Typography>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <div>
      <React.Fragment>
        <Button onClick={() => setModalShow(true)}>
          <img src={Vector} alt="Vector" />
        </Button>
        <MyModal show={modalShow} closeModal={() => setModalShow(false)} />
      </React.Fragment>
    </div>
  );
}
