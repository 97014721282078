import React from "react";


const TermSetting = (props) => {
 
  return (
    <div className="privacypolicymain">
    <div className="title">
        <h1>Ownerscope End-User License Agreement</h1>
        <span>(Terms of Use) May 23, 2022</span>
    </div>
    <div className="content">
    <h4><b>1. Acceptance of this EULA</b></h4>
    <p>Thank you for visiting our website www.Ownerscope.com (“<b>Website</b>”), and/or your interest in using 
    Ownerscope’s virtual campus products and services, provided by Ownerscope Worldwide, LLC d/b/a 
    Ownerscope (“<b>Ownerscope</b>,” “<b>us</b>” “<b>we</b>” and “<b>our</b>”). By using our Website you agree to be bound by this 
    Ownerscope End-User License Agreement (“<b>EULA</b>”). Please read this EULA carefully.</p>
    <p>This EULA constitutes a binding legal contract between you and Ownerscope and governs your use of all 
    products and services as may be available through our Website, our mobile application, and/or in any 
    other forms or media developed and/or provided by or through Ownerscope (collectively with Our Site, the
    “<b>Services</b>”). </p>
    <p>Your use of the Services and Products is also subject to the Ownerscope End-User Privacy Policy 
    (“<b>Privacy Policy</b>”), which covers how we collect, use, share, and store personal information of users. If 
    you do not agree to or understand this EULA or our Privacy Policy, or any changes thereto, you are not 
    authorized to use our Services.</p>
    <p>Notwithstanding any terms herein, please note that you may not use the Services to promote, facilitate or
    support residential or commercial brokerage services in the field of real estate, or real estate agent 
    services (except in conducting real estate business authorized by, and in support of, one of the real estate
    companies owned by Ownerscope’s parent company). Please see Section 16 (Prohibited Use), part p), 
    for additional details.</p>
    <h4><b>2. Age Requirements</b></h4>
        <p>The Services are not intended for or designed to attract children under the age of thirteen (13). By using 
        the Services you affirm that you are at least eighteen (18) years of age or the age of majority in your 
        jurisdiction, or an emancipated minor and are fully able and competent to enter into the terms, conditions, 
        obligations, affirmations, representations, and warranties set forth in this EULA, and to abide by and 
        comply with this EULA.</p>
    <h4><b>3. Grant of License</b></h4>
        <p>Subject to your continuing compliance with this EULA, Ownerscope hereby grants you a limited, non-
        exclusive, non-transferable, non-sublicensable, revocable license to use the Services.</p>
    <h4><b>4. Self-Reflection Purposes Only</b></h4>
        <p>You acknowledge that Ownerscope’s Services are for self-reflection purposes and are not a professional 
        assessment of your personality or your financial status.  The personality results that you receive are only 
        intended to help you to consider your own attitudes toward owning property and to discuss your views 
        with other people.  Ownerscope makes no representations or warranties whatsoever regarding the 
        personality results that you receive, including but not limited to the accuracy, reliability, or uniqueness of 
        your results.</p>
    <h4><b>5. Intellectual Property Rights</b></h4>
        <p>Ownerscope and its licensors own all right, title, and interest in the Services, including without limitation 
        all copyright, trademark, trade secret, and patent right, and reserves all such intellectual property rights. 
        Using our Services does not give you any ownership in our Services or the content or information made 
        available through our Services, and you are only permitted to use our Services for the purposes 
        authorized in this Agreement. Trademarks and logos used in connection with the Services are the 
        trademarks of their respective owners. Ownerscope and other Ownerscope trademarks, service marks, 
        graphics, and logos used for our Services are trademarks or registered trademarks of Ownerscope. You 
        are prohibited from using Ownerscope’s trademarks for any purpose, including without limitation to 
        suggest that you or your Organization are sponsored or endorsed by Ownerscope, without the prior 
        written consent of Ownerscope.</p>
    <h4><b>6. Other Content, Sites and Apps and Content Removal</b></h4>
        <p>Ownerscope.com may contain links to other websites maintained by third parties. These links are 
            provided for convenience and reference only. Ownerscope does not operate or control the information, 
            software, products or services they offer on these sites. Your use of such third-party content, products, or 
            services shared through our Services, is at your own risk. Ownerscope generally does not review and is 
            not responsible for third-party content, products, or services provided through our Services. You are 
            responsible for deciding if you want to access or use third-party content, products, and services (including
            without limitation, apps or sites that link from our Services, if any). Third-party apps and sites have their 
            own legal terms and privacy policies, and you may be giving others permission to use your information in 
            ways we would not. You should carefully read the legal terms and privacy policies of any sites or apps 
            linked from the Services before deciding whether to use those sites or apps. You also understand that 
            Ownerscope does not, and has no obligation to, monitor, pre-screen or pre-approve, or evaluate the 
            integrity of any third-party content, products, or services offered through our Services, but that 
            Ownerscope shall nonetheless have the right, but not the obligation, in its sole discretion, to refuse, delete
            or remove any third-party content, products, or services that may be offered through the Services for any 
            reason, including without limitation a violation of applicable law or any of this EULA.</p>
    <h4><b>7. Data Storage</b></h4>
        <p>We may change or discontinue any of our Services, subject to this EULA. Ownerscope is not a storage 
            service, and you agree that we have no obligation to store, maintain or provide you a copy of any quiz 
            responses that you or others provide through the Services, except to the extent required by applicable law
            and as noted in our Privacy Policy.</p>
    <h4><b>8. Revisions</b></h4>
        <p>Ownerscope reserves the right to revise any of the terms and conditions contained in this EULA and our 
            Privacy Policy (including our policy on use of cookies) at any time and in our sole discretion by posting 
            such revisions on our Website. The effective date of such revisions will be the date specified. It is your 
            responsibility to regularly review this EULA and the Privacy Policy for any revisions. If you do not accept 
            such revisions, you must immediately discontinue using Ownerscope’s Services. Your continued use of 
            Ownerscope’s Services following the effective date of such revisions will signify your acceptance of such 
            revisions. Ownerscope also reserves the right to patch, modify, update, upgrade, disable, suspend, or 
            remove portions of the Services at any time, such activities also being collectively referred to as 
            “revisions.” Such revisions may be implemented by Ownerscope without prior notice to you, and without 
            obtaining additional consent from you. You are not guaranteed rights to any such revisions that may be 
            offered or implemented by Ownerscope, unless otherwise expressly agreed upon in writing between you 
            and Ownerscope.</p>
    <h4><b>9. Notices</b></h4>
        <p>Except as otherwise expressly set forth in this EULA, all notices from Ownerscope to you under the EULA
            will be in writing sent by email. All notices from you to Ownerscope shall be emailed to 
            <a href="mailto:connect@Ownerscope.com">connect@Ownerscope.com</a>, except for legal notices, such as notices regarding copyright infringement 
            assertions, which shall be sent to legal@Ownerscope.com. Notices will be deemed to have been duly 
            given the day after it is sent. </p>
    <h4><b>10. Prohibited Use</b></h4>
        <p>You represent and warrant that you will not use the Services and/or Products for any of the following
            purposes or activities:</p>
            <ul>
                <li>conducting or supporting illegal activity of any type whatsoever, or using the Products or Services in 
                    violation of any applicable law or regulation, including without limitation laws relating to privacy and data 
                    security;</li>
                <li>transmitting or storing worms or viruses, ransomware, or any malicious code designed to access 
                    information without authorization, steal it, intercept it, or alter it, or for any other unauthorized or 
                    destructive purpose;</li>
                <li>threatening, defaming, slandering, libeling, harassing, abusing, impersonating, injuring or
                    intimidating others;</li>
                <li>interfering with others' use of the Services;</li>
                <li>delivering spam or collecting information to deliver spam, or sending advertisements;</li>
                <li>copying, reselling, reproducing, distributing, leasing, sublicensing, or otherwise transferring, the 
                    Services or access thereto, or any reports or data generated therefrom;</li>
                <li>offering to enable, or enabling any third parties to use the Services;</li>
                <li>commercially exploiting, decompiling, disassembling, reverse engineering or otherwise attempting to 
                    discover any source code contained on or in Services;</li>
                <li>displaying or publishing the Products or Services, including without limitation on any website or service, 
                    or using the Services to create, develop, produce, or market a substantially similar product or service;</li>
                <li>making any modification, adaptation, improvement, enhancement, translation or derivative work from 
                    the Services;</li>
                <li>disguising the origin of any content transmitted through Services or obscuring or misrepresenting your 
                    account identity on the Services;</li>
                <li>causing the launch of any automated system(s) that access the Services in a manner that sends more 
                    request messages to servers of the Services in a given period of time than a human can reasonably 
                    produce in the same period by using a conventional on-line web browser (e.g., Denial-of-Service attack);</li>
                <li>data mining, scraping, crawling, redirecting, or compiling data for any purpose other than one 
                    authorized pursuant to this EULA;</li>
                <li>infringing or using any proprietary information or interfaces of the Services or other intellectual property
                    of the Services for any reason not authorized by this EULA; or</li>
                <li>attempting to remove or obscure any proprietary notices on the Services.</li>
                <li>promoting, facilitating or supporting residential or commercial brokerage services in the field of real 
                    estate, or real estate agent services (or analogous services, as may be the case outside of the United 
                    States, such as, for example, without limitation, “estate agent” services that facilitate residential or 
                    commercial property transactions), except for conducting real estate business directly authorized by, and 
                    in support of, one of the real estate companies owned by Ownerscope’s parent company.</li>
            </ul>
        <h4><b>11. Indemnification</b></h4>
        <p>You irrevocably agree to defend, indemnify, and hold harmless Ownerscope, its holding company, and its 
            or their shareholders, officers, directors, members, managers, employees, independent contractors, 
            licensors, agents, subsidiaries, and affiliates (collectively, the “<b>Ownerscope Parties</b>”), jointly, severally, 
            and in any combination, from and against any and all losses, damages, costs, liabilities, assessments, 
            expenses (including but not limited to all fees and charges of attorneys and other professionals, and all 
            dispute resolution costs and litigation costs), and amounts paid in settlement (singularly, a “<b>Loss</b>” and 
            collectively, “<b>Losses</b>”) by or on behalf of any Ownerscope Parties incurred in connection with any actual, 
            threatened, pending, or completed claim, action, suit, mediation, arbitration, alternate dispute resolution 
            process, investigation, administrative hearing, appeal, audit, or any other proceeding (collectively, 
            “<b>Claims</b>”) made or brought against any Ownerscope Parties (including claims by governmental entities 
            seeking to impose penal and/or civil sanctions) alleging any of the following: (a) your breach of this EULA;
            (b) your breach of the Privacy Policy; (c) your violation of applicable law; (d) that your User Generated 
            Content or your use of the Services or Products, breaches any of your representations and warranties in 
            this EULA; (e) any inaccuracy in any of your representations or warranties in this EULA; (f) any 
            allegations that your User Generated Content violates the proprietary rights of a third-party; and (g) your 
            use of the Products and Services.</p>
        <h4><b>12. Copyright Infringement Assertions</b></h4>
        <p>If you have a "good faith" belief that third-party content provided on Ownerscope infringes your intellectual
            property rights, and you wish for Ownerscope to consider the alleged infringing content, you should 
            provide the following information to us: (a) identify, with specificity, your protected work that you 
            reasonably believe is being infringed upon; (b) identify the content that you reasonably believe is 
            infringing upon your protected work, including sufficient information to allow Ownerscope to locate the 
            alleged infringing content; (c) provide your contact information, including full name, mailing address, 
            telephone number, and email address; (d) provide a written statement that you have a "good faith" belief 
            that the use of the content in the manner complained of is not authorized by the copyright owner, its 
            agent, or the law; (e) provide this statement: "I swear, under penalty of perjury, that the information in this 
            notification and complaint is accurate and that I am the copyright owner, or am authorized to act on behalf
            of the copyright owner of an exclusive right that is infringed."; (f) provide your signature on the notification 
            (or an electronic signature); (g) send your notification to our Designated Agent by both mail and e-mail to 
            the following addresses:</p>
            <div className="address">
                <span>Ownerscope Worldwide LLC d/b/a</span>
                    <label>Ownerscope</label>
                      <label>Attn: Designated Copyright Agent
                      9004 161st Street, Suite 308 
                      Queens, NY  11432 </label>
                <span>Email: legal@ownerscope.com</span>
            </div>
        <h4><b>13. Warranty Disclaimer</b></h4>
                <p>YOU AGREE THAT YOUR USE OF THE SERVICES IS AND SHALL BE AT YOUR SOLE RISK. TO THE
                    FULLEST EXTENT PERMITTED BY LAW, OWNERSCOPE (ON BEHALF OF ITSELF AND THE
                    OWNERSCOPE PARTIES) DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING 
                    WITHOUT LIMITATION ANY WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A 
                    PARTICULAR PURPOSE, PERFORMANCE, QUIET ENJOYMENT, AND NON-INFRINGEMENT, IN 
                    CONNECTION WITH THE PRODUCTS AND SERVICES AND YOUR USE OF THE PRODUCTS AND 
                    SERVICES AND OUR SITE. OWNERSCOPE MAKES NO WARRANTIES OR REPRESENTATIONS 
                    ABOUT THE ACCURACY, CURRENCY, OR COMPLETENESS OF THE SERVICES, OUR SITE, OR 
                    THE CONTENT OF ANY SITES LINKED TO OUR SITE. THE SERVICES ARE PROVIDED ON AN “AS
                    IS” “AS AVAILABLE” BASIS. OWNERSCOPE DOES NOT WARRANT THAT THE SERVICES
                    WILL BE SECURE, UNINTERRUPTED, OR ERROR-FREE. YOU ACKNOWLEDGE THAT, DUE TO 
                    THE NATURE OF THE INTERNET, TRANSMISSIONS TO AND FROM THE SERVICES MAY BE
                    INTERCEPTED BY THIRD PARTIES. THE OWNERSCOPE PARTIES DO NOT WARRANT, ENDORSE,
                    GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
                    OFFERED BY A THIRD PARTY THROUGH THE SERVICES, AND THE OWNERSCOPE PARTIES 
                    WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY 
                    TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</p>
        <h4><b>14. Limitation of Liability</b></h4>
                <p>IN NO EVENT SHALL ANY OF THE OWNERSCOPE PARTIES BE JOINTLY OR SEVERALLY LIABLE 
                    TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, EXEMPLARY, SPECIAL, PUNITIVE, OR 
                    CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY OF THE FOLLOWING: (I) ACT
                    BEYOND OWNERSCOPE’S CONTROL; (II) ERRORS, MISTAKES, OR INACCURACIES OF THE 
                    SERVICES; (III) LOSSES, PERSONAL INJURY, OR PROPERTY DAMAGE OF ANY NATURE 
                    WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OWNERSCOPE’S SERVICES; 
                    (IV) ANY UNAUTHORIZED ACCESS TO OR USE OF OWNERSCOPE’S SECURE SERVERS AND/OR 
                    ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (V) ANY INTERRUPTION OR 
                    CESSATION OF TRANSMISSION TO OR FROM THE SERVICES; (VI) ANY BUGS, VIRUSES, TROJAN
                    HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY
                    THIRD PARTY; AND (VII) ANY LOSSES, PERSONAL INJURY, OR PROPERTY DAMAGE OF ANY 
                    NATURE WHATSOEVER RESULTING FROM YOUR USE OF ANY THIRD-PARTY CONTENT, 
                    PRODUCTS, OR SERVICES SHARED THROUGH OUR SERVICES; WHETHER BASED ON
                    WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT
                    OWNERSCOPE, OR ANY OTHER PARTY, IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 
                    THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED
                    BY LAW IN THE APPLICABLE JURISDICTION. YOU SPECIFICALLY ACKNOWLEDGE THAT THE 
                    OWNERSCOPE PARTIES SHALL NOT BE LIABLE FOR USER GENERATED CONTENT (INCLUDING 
                    THE QUALITY, VERACITY, ACCURACY, AND/OR COMPLETELESS THEREOF), OR THE 
                    DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY AND THAT THE RISK 
                    OF HARM OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU.</p>
        <h4><b>15. Severability/Waiver/Construction</b></h4>
            <p>If a court of competent jurisdiction determines that any provision of this EULA is invalid or unenforceable 
                    for any reason, that provision shall be severed from this EULA (in that jurisdiction only) and the remainder
                    of the EULA will continue in full force and effect. Delay or failure to enforce or insist on strict compliance 
                    with any of the provisions of this EULA will not constitute a waiver of that or any other provision or 
                    otherwise modify this EULA. Ownerscope’s waiver of any right related to this EULA must be express and 
                    in writing in order to be valid; any waiver by Ownerscope on one occasion will not waive any other right, 
                    constitute a continuing waiver, or waive that right on any other occasion. You should consult with your 
                    attorney before agreeing to the terms of this EULA. Your agreement to this EULA constitutes your 
                    affirmation that you have consulted with, or have had the opportunity to consult with, your attorney 
                    regarding this EULA. Therefore, no rule of construction or interpretation that disfavors the drafting party 
                    will apply to interpretation of this EULA.</p>
        <h4><b>15. General Provisions</b></h4>
                <p>This EULA, the Privacy Policy, and any additional terms or policies for use of the Services provided to you
                    in writing by Ownerscope (which terms and policies are hereby incorporated into this EULA by this 
                    reference), constitute the entire agreement between you and Ownerscope with respect to the subject 
                    matter contained herein. Any action related to this EULA or the Privacy Policy, and any documents 
                    incorporated by reference herein, will be governed by, construed and enforced in accordance with the 
                    substantive and procedural laws of the State of New York (without regard to its conflict of laws 
                    provisions), and to the extent controlling, to the federal laws of the United States of America.</p>
                <p>By using the Services you are agreeing to the terms of this EULA, and in doing so, you acknowledge and 
                    agree that any disputes, actions, claims or causes of action arising out of or in connection with this EULA 
                    or the Privacy Policy, and any documents incorporated by reference herein, shall be subject to the 
                    exclusive jurisdiction of the state and federal courts applicable to Queens County, New York. Ownerscope
                    reserves the right to initiate litigation in any other appropriate forum as needed.</p>
                <p>This EULA and the Privacy Policy, and all documents incorporated by reference herein, have been 
                    prepared and are to be interpreted in the English (American) language. To the extent there is any conflict 
                    between the terms of this EULA and our Privacy Policy, one the one hand, and any additional terms or 
                    policies that are incorporated by reference herein, on the other hand, the terms of this EULA and our 
                    Privacy Policy are controlling. To the extent there is any conflict between the terms of this EULA and the 
                    terms of our Privacy Policy, the terms of our Privacy Policy are controlling.</p>
            <h4><b>17. Ownerscope Community Policies</b></h4>
                <p>Ownerscope strives to foster a positive, stimulating and safe community for encouraging dialogue about 
                    the important goal of home ownership. We believe that almost all of our users will naturally contribute to 
                    promoting such dialogue, but as a reminder, here are some guidelines (not intended to be 
                    comprehensive) to keep in mind.</p>
                <ul className="listing">
                    <li>No Bullying and/or Harassment - Ownerscope encourages users to speak freely on matters 
                        and people of public interest, but we strive to take action on reports of abusive, harassing, 
                        threatening, or defamatory behavior directed at individuals. Repeatedly targeting users with 
                        unwanted messages is a form of harassment.</li>
                    <li>Sharing, posting, or transmitting any material or communication that is designed to provoke, 
                        harass, upset, embarrass, or antagonize anyone (including Ownerscope staff or other users), 
                        especially trolling, bullying, or intimidating, is strictly prohibited within any Service of 
                        Ownerscope;</li>
                    <li>Do not impersonate - Ownerscope requests users use their real names and account identities
                        when using the Services. Users are prohibited from publishing the personal information of 
                        others without their consent. Claiming to be another person, creating a false presence for an 
                        organization or person, or creating multiple accounts undermines community and violates 
                        Ownerscope's EULA.</li>
                    <li>No Hateful Speech - Ownerscope does not permit use of the Services to disseminate hateful 
                        speech or to incite violence of any kind. While we encourage you to challenge ideas, 
                        institutions, events, and practices, Ownerscope does not permit individuals or groups to use 
                        the Services to attack others based on their race, ethnicity, national origin, religion, sex, 
                        gender, sexual orientation, familial status, disability or medical condition, or other protected 
                        class.</li>
                    <li>Graphic Content - Ownerscope can be a place where people turn to share their experiences 
                        and raise awareness about issues important to them. Sometimes, those experiences and 
                        issues involve graphic content that is of public interest or concern. In many instances, when 
                        people share this type of content, it is to condemn it. However, graphic images shared for 
                        sadistic effect or to celebrate or glorify violence will not be tolerated on our Services.</li>
                    <li>Nudity - Ownerscope has a strict policy against the sharing of pornographic content and any 
                        explicitly sexual content. We also impose limitations on the display of nudity.</li>
                    <li>Intellectual Property - Before sharing content on Ownerscope, please be sure you have the 
                        right to do so. We ask that you respect copyrights, trademarks, and other legal rights 
                        including, but not limited to, not conducting copyright infringement, trademark infringement, 
                        defamation, invasion of privacy, identity theft, hacking, stalking, fraud and the distribution of 
                        counterfeit software.</li>
                    <li>Phishing and Spam - We take the safety of our users seriously and work to prevent attempts 
                        to compromise their privacy or security. We also ask that you respect our other users by not 
                        contacting them for commercial purposes without their consent.</li>
                </ul>
        </div>
    </div>
  );
};

export default TermSetting;